import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './cases.module.css';
import { useCaseData } from 'hooks/useCaseData';
import { useTag } from '../tags/tagContext';

const Routes = require('../routes').Routes;

interface IPreviewItem {
  linkTo: string;
  title: string;
  name: string;
  subtitle: string;
  logoImg?: string | null;
  IslogoSvg?: boolean;
  coverImgUrl?: string;
  itemStyles: string;
}

const PreviewItem = ({
  linkTo,
  title,
  subtitle,
  logoImg,
  itemStyles,
  coverImgUrl,
  name,
  IslogoSvg = false,
}: IPreviewItem) => {
  const data = useStaticQuery(graphql`
    query {
      brocoders: file(relativePath: { eq: "img/brocoders.svg" }) {
        publicURL
      }
      everypig: file(relativePath: { eq: "img/everypig.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      anuel: file(relativePath: { eq: "img/anuel.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gokada: file(relativePath: { eq: "img/gokada.svg" }) {
        publicURL
      }
      korobox: file(relativePath: { eq: "img/korobox.svg" }) {
        publicURL
      }
      backbone: file(relativePath: { eq: "img/backbone.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ladder: file(relativePath: { eq: "img/ladder.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dokka: file(relativePath: { eq: "img/dokka.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tradersAlloy: file(relativePath: { eq: "img/traders-alloy.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      appetini: file(relativePath: { eq: "img/appetini.svg" }) {
        publicURL
      }
      condogenie: file(relativePath: { eq: "img/condogenie.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beyondGreen: file(relativePath: { eq: "img/beyond-green.svg" }) {
        publicURL
      }

      skilent: file(relativePath: { eq: "img/skilent.svg" }) {
        publicURL
      }
      mycurrency: file(relativePath: { eq: "img/mycurrency.svg" }) {
        publicURL
      }
      xelladoor: file(relativePath: { eq: "img/xelladoor.svg" }) {
        publicURL
      }
      geriana: file(relativePath: { eq: "img/geriana.svg" }) {
        publicURL
      }
      iFit: file(relativePath: { eq: "img/i-fit.svg" }) {
        publicURL
      }
      adact: file(relativePath: { eq: "img/adact-logo.svg" }) {
        publicURL
      }
      hypeboard: file(relativePath: { eq: "img/hypeboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      converthero: file(relativePath: { eq: "img/converthero-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      anuelBg: file(relativePath: { eq: "img/anuel-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appetiniBg: file(relativePath: { eq: "img/appetini-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      backboneBg: file(relativePath: { eq: "img/backbone-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beyondBg: file(relativePath: { eq: "img/beyond-green-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      condogenieBg: file(relativePath: { eq: "img/condogenie-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dokkaBg: file(relativePath: { eq: "img/dokka-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dokkaBg: file(relativePath: { eq: "img/dokka-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      epBg: file(relativePath: { eq: "img/ep-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      everypigBg: file(relativePath: { eq: "img/everypig-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gerianaBg: file(relativePath: { eq: "img/geriana-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gokadaBg: file(relativePath: { eq: "img/gokada-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iFitBg: file(relativePath: { eq: "img/i-fit-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      koroboxBg: file(relativePath: { eq: "img/korobox-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ladderBg: file(relativePath: { eq: "img/ladder-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mycurrencyBg: file(relativePath: { eq: "img/mycurrency-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adactBg: file(relativePath: { eq: "img/adact-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      skilentBg: file(relativePath: { eq: "img/skilent-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      xellardoorBg: file(relativePath: { eq: "img/xellardoor-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      convertheroBg: file(relativePath: { eq: "img/converthero-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hypeboardBg: file(relativePath: { eq: "img/bg_img_hypeboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      brocodersBg: file(relativePath: { eq: "bg_img_brocoders.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heypractice_logo: file(relativePath: { eq: "heypractice/logo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heypractice_cover: file(relativePath: { eq: "heypractice/cover.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      myRedDog: file(relativePath: { eq: "img/myRedDog.svg" }) {
        publicURL
      }
      reddogBg: file(relativePath: { eq: "reddog/reddog.png" }) {
        childImageSharp {
          fluid(maxWidth: 1720, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tradersAlloyBg: file(relativePath: { eq: "traders-alloy-cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wagepoint_logo: file(relativePath: { eq: "img/wagepoint-logo.svg" }) {
        publicURL
      }
      moolax_logo: file(relativePath: { eq: "img/moolax-logo.svg" }) {
        publicURL
      }
      wagepoint_cover: file(relativePath: { eq: "wagepoint/cover.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      moolax_cover: file(relativePath: { eq: "moolax/cover.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      areabutler_logo: file(relativePath: { eq: "case-logos/area-butler-logo.svg" }) {
        publicURL
      }
      areabutler_cover: file(relativePath: { eq: "area-butler.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      revenue_boosters_logo: file(relativePath: { eq: "case-logos/revenue-boosters_logo.svg" }) {
        publicURL
      }
      revenue_cover: file(relativePath: { eq: "revenue-boost/revenue_boosters_cover.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const { activeTag, onTagClick } = useTag();

  const tagsData = useCaseData()[name.toLowerCase()] ? useCaseData()[name.toLowerCase()] : null;
  return (
    <div
      className={styles.item}
      style={
        {
          '--bg-img': coverImgUrl
            ? `url(${data[`${coverImgUrl}`].childImageSharp.fluid.srcWebp})`
            : '',
        } as React.CSSProperties
      }
    >
      <Link to={linkTo} aria-label={name}>
        <div className={clsx(styles.tile, itemStyles)}>
          {!!logoImg && (
            <img
              src={
                IslogoSvg
                  ? `${data[`${logoImg}`].publicURL}`
                  : `${data[`${logoImg}`].childImageSharp.fluid.srcWebp}`
              }
              alt="logo"
              className={styles.logo}
            />
          )}{' '}
        </div>
      </Link>

      <Link to={linkTo} className={styles.link}>
        <h2 className={styles.title}>{title}</h2>
      </Link>
      <ul className={styles.tagList}>
        {tagsData &&
          tagsData.map(({ tag_name }) => (
            <li
              className={
                tag_name === activeTag ? clsx(styles.tagItem, styles.activeTag) : styles.tagItem
              }
              key={tag_name}
              onClick={() => onTagClick(tag_name)}
            >
              {tag_name}
            </li>
          ))}
      </ul>
      <p className={styles.subtitle}>{subtitle}</p>
    </div>
  );
};

function Brocoders() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_BROCODERS}
      title="Reinventing our old-hat company website"
      subtitle="Backed by multi-year corporate website development expertise and almost 100 projects under
    our belt, we reinvented our site to keep pace with modern trends"
      logoImg="brocoders"
      IslogoSvg={true}
      coverImgUrl="brocodersBg"
      itemStyles={styles.brocoders}
      name="brocoders"
    />
  );
}
function EverypigLogistic() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_EVERYPIG_LOGISTIC}
      title="Online Logistics Platform for Swine Production Industry"
      subtitle="Acquire a sneak peek at how Brocoders strengthen an already-existing Everypig solution with
      a new powerful logistics core"
      logoImg="everypig"
      coverImgUrl="everypigBg"
      itemStyles={styles.everypig}
      name="EverypigLogistic"
    />
  );
}

function Anuel() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_ANUEL}
      title="CRM and Marketplace for a Solar Energy Company"
      subtitle="How Brocoders helped Anuel Energy to convert their business processes into the solid
      software ecosystem"
      logoImg="anuel"
      coverImgUrl="anuelBg"
      itemStyles={styles.anuel}
      name="anuel"
    />
  );
}

function Gokada() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_GOKADA}
      title="Ridesharing App for On-demand Transporting Company"
      subtitle="Developing Gokada solution ─ high loaded cross platform mobile application with advanced
      integrations"
      logoImg="gokada"
      IslogoSvg={true}
      coverImgUrl="gokadaBg"
      itemStyles={styles.gokada}
      name="Gokada"
    />
  );
}
function Korobox() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_KOROBOX}
      title="Cross-platform App for Office Snack Service"
      subtitle="A detailed overview of building Korobox, an app that simplifies buying snacks and tracking
      the calories"
      logoImg="korobox"
      IslogoSvg={true}
      coverImgUrl="koroboxBg"
      itemStyles={styles.korobox}
      name="korobox"
    />
  );
}
function Backbone() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_BACKBONE}
      title="Event Production Management Software"
      subtitle="Feature-rich custom solution that streamlines organization and execution of events of any
      type | Backbone International"
      logoImg="backbone"
      coverImgUrl="backboneBg"
      itemStyles={styles.backbone}
      name="Backbone"
    />
  );
}
function LadderOut() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_LADDEROUT}
      title="Mental Health Coaching Software"
      subtitle="Learn more how we built LadderOut - a guided interactive program and skill-building software
      that helps quit porn addiction"
      logoImg="ladder"
      coverImgUrl="ladderBg"
      itemStyles={styles.ladder}
      name="Ladderout"
    />
  );
}
function Dokka() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_DOKKA}
      title="Bookkeeping and Document Management Company"
      subtitle="Take a sneak peek at how Brocoders delivered an accounting automation solution designed to
      make life easier for bookkeepers and accounting companies, as well as their end-customers"
      logoImg="dokka"
      coverImgUrl="dokkaBg"
      itemStyles={styles.dokka}
      name="dokka"
    />
  );
}
function Appetini() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_APPETINI}
      title="Lunch Delivery Solution"
      subtitle="Take a sneak peek at how we developed a food delivery service platform to cover our needs
      when ordering lunches for offices in our city"
      logoImg="appetini"
      IslogoSvg={true}
      coverImgUrl="appetiniBg"
      itemStyles={styles.appetini}
      name="appetini"
    />
  );
}
function Condogenie() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_CONDOGENIE}
      title="Condominium Management System"
      subtitle="Read how Brocoders developed a feature-rich product to help condo residents, condo board
      directors and property managers effectively cope with numerous condominium-related issues"
      logoImg="condogenie"
      coverImgUrl="condogenieBg"
      itemStyles={styles.condogenie}
      name="condogenie"
    />
  );
}
function BeyondGreen() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_BEYOND_GREEN}
      title="Online Ordering System"
      subtitle="How Brocoders developed a client-oriented menu ordering application to help Beyond Green
      Partners streamline internal management processes and enable fast and optimized food
      delivery"
      logoImg="beyondGreen"
      IslogoSvg={true}
      coverImgUrl="beyondBg"
      itemStyles={styles.beyondGreen}
      name="beyondGreen"
    />
  );
}
function Skilent() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_SKILENT}
      title="Next-generation Recruiting Software"
      subtitle="Learn more about development of fully-featured recruiting software for International IT
      consulting company Comtech LLC that helps hiring managers and recruiters optimize their
      workflow"
      logoImg="skilent"
      IslogoSvg={true}
      coverImgUrl="skilentBg"
      itemStyles={styles.skilent}
      name="skilent"
    />
  );
}
function MyCurrency() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_MYCURRENCY}
      title="E-commerce platform for loyalty points exchanges"
      subtitle="Learn how we developed MyCurrency, the first and the only mobile app that allows creating
      own micro currency and sharing it with local partners"
      logoImg="mycurrency"
      IslogoSvg={true}
      coverImgUrl="mycurrencyBg"
      itemStyles={styles.mycurrency}
      name="mycurrency"
    />
  );
}
function Xellardoor() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_XELLARDOOR}
      title="Xellardoor"
      subtitle="Take a sneak peak how Brocoders developed feature-rich online fashion marketplace Xellardoor
      with outstanding features for fashion enthusiasts"
      logoImg="xelladoor"
      IslogoSvg={true}
      coverImgUrl="xellardoorBg"
      itemStyles={styles.xellardoor}
      name="xellardoor"
    />
  );
}
function Geriana() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_GERIANA}
      title="Peer to Peer Elderly Caregiving platform"
      subtitle="Geriana is a marketplace called to be an all-European network for providing social services.
      Learn more about Brocoders’ commitment to the development of this project"
      logoImg="geriana"
      IslogoSvg={true}
      coverImgUrl="gerianaBg"
      itemStyles={styles.geriana}
      name="geriana"
    />
  );
}
function IFit() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_I_FIT}
      title="Advanced Integrations for Interactive Workout Application"
      subtitle="Read how our Node.js development team helped interactive Fitness and Workout App add new complex integrations"
      logoImg={null}
      coverImgUrl="iFitBg"
      itemStyles={styles.iFit}
      name="iFit"
    />
  );
}
function GymClub() {
  const tagsData = useCaseData()['GymClub'.toLowerCase()];
  const { activeTag, onTagClick } = useTag();
  return (
    <div className={styles.item}>
      <Link to={Routes.CASE_STUDIES_GYM_CLUB} aria-label="GymClub">
        <div className={clsx(styles.tile, styles.gymClub)} />
      </Link>
      <Link to={Routes.CASE_STUDIES_GYM_CLUB} className={styles.link}>
        <h2 className={styles.title}>Gym Management System</h2>
      </Link>
      <ul className={styles.tagList}>
        {tagsData &&
          tagsData.map(({ tag_name }) => (
            <li
              className={
                tag_name === activeTag ? clsx(styles.tagItem, styles.activeTag) : styles.tagItem
              }
              key={tag_name}
              onClick={() => onTagClick(tag_name)}
            >
              {tag_name}
            </li>
          ))}
      </ul>
      <p className={styles.subtitle}>
        Delivering a next-gen gym experience with a custom-built hardware &amp; software package
      </p>
    </div>
  );
}
function Everypig() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_EVERYPIG}
      title="AI-based Swine Management Software"
      subtitle="A case study on developing a groundbreaking management software for pig owners, producers
      and veterinarians"
      logoImg="everypig"
      coverImgUrl="epBg"
      itemStyles={styles.ep}
      name="Everypig"
    />
  );
}
function Adact() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_ADACT}
      title="Marketing Gamification Platform"
      subtitle="An overview of our commitment to building the cloud-based gamification platform"
      logoImg="adact"
      IslogoSvg={true}
      coverImgUrl="adactBg"
      itemStyles={styles.adact}
      name="Adact"
    />
  );
}
function Hypeboard() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_HYPEBOARD}
      title="Instagram analytics tool"
      subtitle="Developing cross-platform mobile application Hypeboard that shows real-time rankings of
      Instagram profiles"
      logoImg="hypeboard"
      coverImgUrl="hypeboardBg"
      itemStyles={styles.hypeboard}
      name="hypeboard"
    />
  );
}
function Converthero() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_CONVERTHERO}
      title="Exit-intent popup software"
      subtitle="A case study on developing exit-intent popup software with advanced popup builder to
      increase user engagement and conversion rate"
      logoImg="converthero"
      coverImgUrl="convertheroBg"
      itemStyles={styles.converthero}
      name="converthero"
    />
  );
}
function Heypractice() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_HEYPRACTICE}
      title="AI-based Learning Platform"
      subtitle="How Brocoders helped to build an interactive learning platform and integrate AI-based units to create
      an exclusive training tool for Sales and Marketing teams"
      logoImg="heypractice_logo"
      coverImgUrl="heypractice_cover"
      itemStyles={styles.heypractice}
      name="heypractice"
    />
  );
}
function RedDog() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_HOA_MANAGMENT}
      title="HOA Management Application"
      subtitle="Learn how we helped a leading property management company build a PWA app that allows homeowners to interact with their property owner’s association"
      logoImg="myRedDog"
      IslogoSvg={true}
      coverImgUrl="reddogBg"
      itemStyles={styles.myRedDog}
      name="MyReddog"
    />
  );
}
function TradersAlloy() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_TRADERS_ALLOY}
      title="CI/CD pipeline for a Fintech startup"
      subtitle="Check out how Brocoders DevOps Engineers gave Traders Alloy a smoother path to production by switching from manual to automated deployment and making it 5x time faster"
      logoImg="tradersAlloy"
      // IslogoSvg={false}
      coverImgUrl="tradersAlloyBg"
      itemStyles={styles.tradersAlloy}
      name="TradersAlloy"
    />
  );
}
function Wagepoint() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_WAGEPOINT}
      title="Payroll Software Renovation"
      subtitle="Learn how we helped a payroll software company release a high-quality product on time by adding 5 qualified engineers to the project, and further scaling the team to 13 experts"
      IslogoSvg={true}
      logoImg="wagepoint_logo"
      coverImgUrl="wagepoint_cover"
      itemStyles={styles.wagepoint}
      name="Wagepoint"
    />
  );
}

function AreaButler() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_AREABUTLER}
      title="Remote team for AI-powered PropTech Startup"
      subtitle="How Brocoders augmented a team of a German PropTech startup and helped to build AI-based features"
      IslogoSvg={true}
      logoImg="areabutler_logo"
      coverImgUrl="areabutler_cover"
      itemStyles={styles.areabutler}
      name="AreaButler"
    />
  );
}

function RevenueBoosters() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_REVENUE_BOOSTERS}
      title="Developing an MVP of a Route Management SaaS App"
      subtitle="How Brocoders built an MVP of a route management SaaS app in 3,5 Months replacing their existing software that stoped functioning"
      IslogoSvg={true}
      logoImg="revenue_boosters_logo"
      coverImgUrl="revenue_cover"
      itemStyles={styles.revenue_boosters}
      name="RevenueBoosters"
    />
  );
}

function Moolax() {
  return (
    <PreviewItem
      linkTo={Routes.CASE_STUDIES_MOOLAX}
      title="Smart Cashflow Management Solution"
      subtitle="How Brocoders helped to build an MVP for a FinTech startup offering a streamlined cash flow management solution for modern specialists and freelancers"
      IslogoSvg={true}
      logoImg="moolax_logo"
      coverImgUrl="moolax_cover"
      itemStyles={styles.moolax}
      name="Moolax"
    />
  );
}

export default [
  {
    name: 'Heypractice',
    params: ['saas-development', 'product-development', 'react', 'node', 'edtech'],
    component: () => Heypractice,
  },
  {
    name: 'Moolax',
    params: ['mvp-development', 'node', 'fintech', 'next', 'strapi'],
    component: () => Moolax,
  },
  {
    name: 'EverypigLogistic',
    params: ['mvp-development', 'product-development', 'react', 'agritech'],
    component: () => EverypigLogistic,
  },
  {
    name: 'AreaButler',
    params: ['react', 'node', 'team-augmentation', 'proptech'],
    component: () => AreaButler,
  },
  {
    name: 'RevenueBoosters',
    params: ['saas-development', 'react', 'node', 'react-native', 'amusement'],
    component: () => RevenueBoosters,
  },
  // {
  //   name: 'Wagepoint',
  //   params: ['team-augmentation', 'fintech', 'react'],
  //   component: () => Wagepoint,
  // },
  {
    name: 'TradersAlloy',
    params: ['devops', 'fintech', 'mvp-development'],
    component: () => TradersAlloy,
  },
  {
    name: 'MyReddog',
    params: ['edtech', 'proptech', 'react', 'mobile-app-dev', 'digital-transformation'],
    component: () => RedDog,
  },
  {
    name: 'Backbone',
    params: ['react', 'node', 'event-management', 'dedicated-team'],
    component: () => Backbone,
  },
  {
    name: 'Skilent',
    params: ['digital-transformation', 'product-development', 'react', 'node', 'recruiting-hr'],
    component: () => Skilent,
  },
  {
    name: 'Everypig',
    params: ['mvp-development', 'product-development', 'react', 'agritech'],
    component: () => Everypig,
  },
  {
    name: 'IFit',
    params: ['react', 'node', 'dedicated-team', 'fitness-software'],
    component: () => IFit,
  },
  {
    name: 'Condogenie',
    params: ['react', 'product-development', 'dedicated-team', 'proptech'],
    component: () => Condogenie,
  },
  {
    name: 'Anuel',
    params: ['react', 'rails', 'react-native', 'product-development', 'mobile-app-dev'],
    component: () => Anuel,
  },
  {
    name: 'LadderOut',
    params: [
      'product-development',
      'node',
      'react',
      'react-native',
      'mvp-development',
      'web-app-dev',
    ],
    component: () => LadderOut,
  },
  {
    name: 'GymClub',
    params: ['react', 'node', 'product-development', 'fitness-software'],
    component: () => GymClub,
  },
  {
    name: 'BeyondGreen',
    params: ['digital-transformation', 'react', 'node', 'foodtech'],
    component: () => BeyondGreen,
  },
  {
    name: 'Appetini',
    params: ['react', 'product-development', 'mvp-development', 'foodtech', 'web-app-dev'],
    component: () => Appetini,
  },
  {
    name: 'Korobox',
    params: [
      'mvp-development',
      'product-development',
      'react',
      'node',
      'react-native',
      'foodtech',
      'mobile-app-dev',
    ],
    component: () => Korobox,
  },
  {
    name: 'Gokada',
    params: [
      'mvp-development',
      'product-development',
      'react',
      'react-native',
      'node',
      'ride-sharing',
      'mobile-app-dev',
    ],
    component: () => Gokada,
  },
  // {
  //   name: 'Dokka',
  //   params: ['fintech'],
  //   component: () => Dokka,
  // },
  // {
  //   name: 'Xellardoor',
  //   params: ['product-development', 'mvp-development'],
  //   component: () => Xellardoor,
  // },
  {
    name: 'Brocoders',
    params: ['product-development', 'gatsby', 'node', 'web-app-dev'],
    component: () => Brocoders,
  },
  {
    name: 'Geriana',
    params: ['react', 'node', 'web-app-dev', 'healthtech'],
    component: () => Geriana,
  },
  {
    name: 'Adact',
    params: ['react', 'node', 'product-development', 'martech'],
    component: () => Adact,
  },
  {
    name: 'MyCurrency',
    params: ['product-development', 'react-native', 'node', 'e-commerce', 'mobile-app-dev'],
    component: () => MyCurrency,
  },
  {
    name: 'Hypeboard',
    params: ['mobile-app-dev', 'react-native', 'node', 'aws', 'martech'],
    component: () => Hypeboard,
  },
  {
    name: 'Converthero',
    params: ['mvp-development', 'martech', 'react', 'ruby-on-rails'],
    component: () => Converthero,
  },
];
